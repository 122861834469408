.DayPicker-Month {
    /* min-width: 20vw; */
    width: 100%;
    /* max-width: 25vw; */
    height: 10vw;
    border-spacing: 5px;
    border-collapse: separate;
    table-layout: fixed;
    /* line-height: 20px; */
  }

.DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    margin: .5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    background-color:#60a6d428;
    color: #60A5D4;
    height: 44px;
    width: 44px;
    margin-right: auto;
    margin-left: auto;
  }

  .DayPicker-Day--today {
    /* color: #D0021B; */
    /* font-weight: 700; */
    border: 3px solid;
    border-color:  #60A5D4;
  }

  .DayPicker-Day--disabled {
    color: #80808054;
    cursor: default;
    background-color: transparent;
    /* background-color: #eff1f1; */
  }

  .DayPicker-Day--outside {
    color: transparent;
    background-color: transparent;
    cursor: default;
  }